
import { entries } from "idb-keyval";
import { Options, Vue } from "vue-class-component";

import { LogEntry, LogService } from "@/services/logService";
import StickyPage from "@/components/sticky-page.vue";
import StickyCollapse from "@/components/sticky/sticky-collapse.vue";
// @ is an alias to /src
@Options({
  name: "DebugView",
  components: {
    StickyPage,
    StickyCollapse,
  },
})
export default class DebugView extends Vue {
  public debug = false;
  public displayMode = "";
  public orientation = "";
  public serviceWorker = "";
  private ls = new LogService();
  public origin = window.location.origin;
  public cookies: Array<{ name: string; value: string }> = [];
  public localStorage: Array<{ name: string; value: string }> = [];
  public dbEntries: Array<{ name: string; value: string }> = [];
  public perf = {
    heapSize: 0,
    percentUsed: 0,
  };
  public errors: Array<LogEntry> = [];
  private errorWatch =0;
  private storageKeys = 0;

  public getLabel(le: LogEntry) {
    const lbl = le.message || le;
    return lbl.toString().substring(0, 35);
  }
  public get refreshCount() {
    return window.ww.initAllCount || 0;
  }
  public showJson(obj: any) {
    window.alert(JSON.stringify(obj));
  }

  public async created() {
    let i = 0;
    while (window.localStorage.key(i)) {
      const key = window.localStorage.key(i) as string;
      this.localStorage.push({
        name: key,
        value: window.localStorage.getItem(key) as string,
      });
      i++;
    }
    const idbVals = await entries();
    idbVals.forEach((kv) => {
      this.dbEntries.push({ name: kv[0] as string, value: kv[1] as string });
    });
    this.storageKeys = i;
    this.displayMode = window.ww?.displayMode;
    this.serviceWorker =
      window.navigator?.serviceWorker?.controller?.state || "no";
    const m = (window.performance as any)?.memory;
    this.perf.heapSize = Math.floor(m?.usedJSHeapSize / 1000) || 0;
    this.perf.percentUsed =
      Math.round((m?.usedJSHeapSize / m?.jsHeapSizeLimit) * 1000) / 10;
    this.cookies = this.getCookies();
    this.orientation = window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape";

    this.errorWatch = setInterval(() => {
      this.errors = this.ls.getLogs() || [];      
    }, 2000);
  }

  public beforeDestroy() {
    clearInterval(this.errorWatch);
  }

  private getCookies() {
    const cookies = [];

    if (document.cookie && document.cookie != "") {
      const split = document.cookie.split(";");
      for (let i = 0; i < split.length; i++) {
        const nameValue = split[i].split("=");
        nameValue[0] = nameValue[0].replace(/^ /, "");
        cookies.push({
          name: decodeURIComponent(nameValue[0]),
          value: decodeURIComponent(nameValue[1]),
        });
      }
    }
    return cookies;
  }
}
