<template>
  <sticky-page>
    <div class="card card-style">
      <div class="content mb-2">
        <div class="d-flex">
          <div>
            <a
              href="#"
              class="icon icon-xl bg-magenta2-dark rounded-s mr-4 mt-2"
            >
              <i class="fa fa-bold color-white font-30 mt-1"></i>
            </a>
          </div>
          <div class="flex-grow-1">
            <h4>
              Runtime Info
              <span class="badge bg-highlight color-white float-right mt-1"
                >1.0.0</span
              >
            </h4>
            <p class="mt-n1 mb-3">
              If you need to report a bug please include the information below.
            </p>
            <ul class="pl-3">
              <li>
                Display Mode:
                <span class="float-right">{{ displayMode }}</span>
              </li>
              <li>
                Orientation:
                <span class="float-right">{{ orientation }}</span>
              </li>
              <li>
                Service Worker:
                <span class="float-right">{{ serviceWorker }}</span>
              </li>
              <li>
                Memory Used:
                <span class="float-right"
                  >{{ perf.heapSize }} KB ({{ perf.percentUsed }} %)</span
                >
              </li>
              <li>
                Origin: <span class="float-right">{{ origin }}</span>
              </li>
              <li>
                Storage Keys:
                <span class="float-right">{{ storageKeys }}</span>
              </li>
              <li>
                Full Refresh:
                <span class="float-right">{{ refreshCount }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-style">
      <div class="content mb-0">
        <div class="list-group list-custom-small list-icon-0 mb-2">
          <h4>Local Data</h4>
          <p class="mt-n1 mb-3">Data stored on your device</p>

          <sticky-collapse
            :label="`Cookies (${cookies.length})`"
            :icon="['far', 'cookie']"
            iconClass="font-14 color-brown1-dark"
          >
            <ul>
              <li v-for="cookie in cookies" :key="cookie.name">
                <!-- <span class="float-left">Name:</span> -->
                <span class="float-left" @click="window.alert(cookie.value)">{{
                  cookie.name
                }}</span>
              </li>
            </ul>
          </sticky-collapse>
          <sticky-collapse
            :label="`LocalStorage (${localStorage.length})`"
            icon="database"
            iconClass="font-14 color-green2-dark"
          >
            <ul>
              <li v-for="entry in localStorage" :key="entry.name">
                <!-- <span class="float-left">Name:</span> -->
                <span class="float-left" @click="alert(entry.value)">{{
                  entry.name
                }}</span>
              </li>
            </ul>
          </sticky-collapse>
          <sticky-collapse
            :label="`IndexedDb / WebSQL (${dbEntries.length})`"
            icon="database"
            iconClass="font-14 color-blue2-dark"
          >
            <ul>
              <li v-for="entry in dbEntries" :key="entry.name">
                <!-- <span class="float-left">Name:</span> -->
                <span class="float-left" @click="alert(entry.value)">{{
                  entry.name
                }}</span>
              </li>
            </ul>
          </sticky-collapse>
        </div>
      </div>
    </div>

    <div class="card card-style">
      <div class="content mb-0">
        <h4>JS Errors</h4>
        <p class="mt-n1 mb-3">Exceptions thrown in your current session:</p>

        <div
          class="list-group list-custom-small list-icon-0 mb-2"
          v-for="(err, i) in errors"
          :key="i"
        >
          <sticky-collapse
            icon-class="color-highlight"
            :icon="['far', 'bug']"
            :label="getLabel(err)"
          >
            <!-- <div
              :id="'vcol-' + i"
              class="list-group list-custom-small ls-xs pl-3"
            >
              <a href="#">hi</a> -->

            <ul class="pl-3">
              <li>
                <p class="lh-norm">{{ err.message }}</p>
              </li>
              <li v-if="err.details">{{ err.details }}</li>
              <li v-if="err.data">
                <a href="#" @click="showJson(err.data)"> As JSON</a>
              </li>
              <li v-if="err.data && err.data.lineno">
                {{ err.data.lineno }}:{{ err.data.colno }}
              </li>
              <li v-if="err.data && err.data.filename">
                {{ err.data.filename }}
              </li>
              <li v-if="err.data && err.data.stack">
                {{ err.data.stack }}
              </li>
            </ul>
            <!-- </div> -->
          </sticky-collapse>
        </div>
      </div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
import { entries } from "idb-keyval";
import { Options, Vue } from "vue-class-component";

import { LogEntry, LogService } from "@/services/logService";
import StickyPage from "@/components/sticky-page.vue";
import StickyCollapse from "@/components/sticky/sticky-collapse.vue";
// @ is an alias to /src
@Options({
  name: "DebugView",
  components: {
    StickyPage,
    StickyCollapse,
  },
})
export default class DebugView extends Vue {
  public debug = false;
  public displayMode = "";
  public orientation = "";
  public serviceWorker = "";
  private ls = new LogService();
  public origin = window.location.origin;
  public cookies: Array<{ name: string; value: string }> = [];
  public localStorage: Array<{ name: string; value: string }> = [];
  public dbEntries: Array<{ name: string; value: string }> = [];
  public perf = {
    heapSize: 0,
    percentUsed: 0,
  };
  public errors: Array<LogEntry> = [];
  private errorWatch =0;
  private storageKeys = 0;

  public getLabel(le: LogEntry) {
    const lbl = le.message || le;
    return lbl.toString().substring(0, 35);
  }
  public get refreshCount() {
    return window.ww.initAllCount || 0;
  }
  public showJson(obj: any) {
    window.alert(JSON.stringify(obj));
  }

  public async created() {
    let i = 0;
    while (window.localStorage.key(i)) {
      const key = window.localStorage.key(i) as string;
      this.localStorage.push({
        name: key,
        value: window.localStorage.getItem(key) as string,
      });
      i++;
    }
    const idbVals = await entries();
    idbVals.forEach((kv) => {
      this.dbEntries.push({ name: kv[0] as string, value: kv[1] as string });
    });
    this.storageKeys = i;
    this.displayMode = window.ww?.displayMode;
    this.serviceWorker =
      window.navigator?.serviceWorker?.controller?.state || "no";
    const m = (window.performance as any)?.memory;
    this.perf.heapSize = Math.floor(m?.usedJSHeapSize / 1000) || 0;
    this.perf.percentUsed =
      Math.round((m?.usedJSHeapSize / m?.jsHeapSizeLimit) * 1000) / 10;
    this.cookies = this.getCookies();
    this.orientation = window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape";

    this.errorWatch = setInterval(() => {
      this.errors = this.ls.getLogs() || [];      
    }, 2000);
  }

  public beforeDestroy() {
    clearInterval(this.errorWatch);
  }

  private getCookies() {
    const cookies = [];

    if (document.cookie && document.cookie != "") {
      const split = document.cookie.split(";");
      for (let i = 0; i < split.length; i++) {
        const nameValue = split[i].split("=");
        nameValue[0] = nameValue[0].replace(/^ /, "");
        cookies.push({
          name: decodeURIComponent(nameValue[0]),
          value: decodeURIComponent(nameValue[1]),
        });
      }
    }
    return cookies;
  }
}
</script>
<style lang="scss" scoped>
.net {
  float: right;
  opacity: 10;
  font-size: 1rem;
}
.lh-norm {
  line-height: normal;
}
</style>